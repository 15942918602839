import request from '@/utils/request'

/**
 * get
 * 列表页面数据获取
 * examid 可选 int 考试id
 * subjectid 可选 int 科目id
 * page 可选 int 页码
 */
export const examrepository = (params = {}) => {
    return request({
        method: 'get',
        url: '/exam-repository/index',
        params
    })
}

/**
 *
 * post
 * examid 必选 int 考试id
 * subjectid 必选 int 科目id
 * repnum 必选 int 题目数量
 * wiki 可选 array 知识库
 * serialnum 可选 string 题号
 * score 可选 int 分数
 * abilitys 可选 array 知识点
 */
export const examrepositoryAdd = (data) => {
    return request({
        method: 'post',
        url: 'examrepository',
        data
    })
}
/**
 * get
 * 新增编辑获取学生课程表详情
 */
export const subject = () => {
    return request({
        method: 'get',
        url: 'site/subject',
    })
}
/**
 * get
 * 获取考试列表
 */
// export const getExam = () => {
//   return request({
//     method: 'get',
//     url: 'site/getExam',
//   })
// }
export const getExam = () => {
    return request({
        method: 'post',
        url: 'site/get-query-cond?need_exist_exam=0',
    })
}
/**
 * get
 * 获取考试科目列表
 */
export const examSubject = (exam_id) => {
    return request({
        method: 'get',
        url: 'site/exam-subject?exam_id=' + exam_id,
    })
}
/**
 * get
 * 获取考点知识库数据
 */
export const abilityList = (subjectid) => {
    return request({
        method: 'get',
        url: 'exam-repository/ability-list?subjectid=' + subjectid,
    })
}
/**
 * post
 * 新增考点知识库数据
 */
export const create = (data) => {
    return request({
        method: 'post',
        url: 'exam-repository/create',
        data
    })
}
/**
 * get
 * 选择年级
 */
export const grade = (exam_id) => {
    return request({
        method: 'get',
        url: 'site/exam-grade?exam_id=' + exam_id,
    })
}
/**
 * get
 * 获取考试名称
 */
export const getExamName = () => {
    return request({
        method: 'get',
        url: 'site/get-exam',
    })
}
/**
 * get
 * 获取考点知识库详情
 */
export const repository = (id) => {
    return request({
        method: 'get',
        url: 'exam-repository/' + id,
    })
}
/**
 * put
 * 编辑考点知识库数据
 */
export const putrepository = (id, data) => {
    return request({
        method: 'put',
        url: 'exam-repository/' + id,
        data
    })
}

/**
 * 文件模板下载
 */
export const scoreTemplate = (data) => {
    return request({
        method: 'post',
        url: 'ranking/score-template',
        responseType: 'blob',
        data: data
    })
}